import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { CurveCarveTop, CurveBulgeBottom } from "../components/Curves/Curve"
import CallBackForm from "../components/Forms/CallBackForm"
import { Newsletter } from "../components/Misc/Newsletter"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact Us" />

    <Container>
      <Row>
        <Col className="text-center">
          <h1>Contact Us</h1>
          <p>Enter your details to request a FREE call back.</p>
        </Col>
      </Row>
    </Container>

    <CurveCarveTop variant="light" />
    <div className="bg-light pt-5">
      <Container>
        <Row>
          <Col lg={4} md={6} className="mx-auto">
            <CallBackForm />
          </Col>
        </Row>
      </Container>
    </div>
    <CurveBulgeBottom variant="light" />
    <Newsletter />
  </Layout>
)

export default ContactPage
